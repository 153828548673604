<template>
  <div class="page-container md-layout-column">
    <div class="container my-progress">
      <h1 class="text-welcome mt-3">
        Lo estás haciendo genial. Retoma donde lo dejaste.
      </h1>
      <div class="row">
        <div class="col-sm-12 mt-4">
          <h2 style="color: #b7227e !important">CONTINUAR VIENDO</h2>
        </div>

        <template v-if="isLoadingClassProgress">
          <div class="col-sm-12">
            <div class="row">
              <div
                v-for="index in 3"
                :key="index"
                class="col-12 col-lg-4 col-md-6"
              >
                <skeleton-card-video />
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-if="getUserProgressClass.length" class="col-sm-12">
            <div class="row">
              <card-class
                v-for="_class in getUserProgressClass"
                :key="_class.id"
                :data="_class"
                :redirect="`/clase/${_class.slug}`"
                class="col-12 col-lg-4 col-md-6"
              />
            </div>
          </div>
          <div v-else class="col-sm-12 mt-4 mb-4">
            <h3>No hay contenido en progreso</h3>
          </div>
        </template>
      </div>

      <div class="row">
        <div class="col-sm-6 mt-4">
          <h2 style="color: #b7227e !important">CLASES COMPLETADAS</h2>
        </div>

        <div class="col-sm-6 mt-4 user-progress-completed-classes">
          <router-link
            class="text-decoration-none"
            to="/user/progreso/clases/completadas"
          >
            <button class="allClasses__container__classes__button rounded">
              Ver Todas
            </button>
          </router-link>
        </div>
        <template v-if="isLoadingClassCompleted">
          <div class="col-sm-12">
            <div class="row">
              <div
                v-for="index in 3"
                :key="index"
                class="col-12 col-lg-4 col-md-6"
              >
                <skeleton-card-video />
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-if="getUserCompletedClass.items.length" class="col-sm-12">
            <div class="row">
              <card-class
                v-for="_class in getUserCompletedClass.items"
                :key="_class.id"
                :data="_class"
                :redirect="`/clase/${_class.slug}`"
                class="col-12 col-lg-4 col-md-6"
              />
            </div>
          </div>
          <div v-else class="col-sm-12 mt-4 mb-4">
            <h3>No hay contenido completado</h3>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  FETCH_USER_CLASS_COMPLETED,
  FETCH_USER_CLASS_PROGRESS,
} from '../../../store/actions/user';
import SkeletonCardVideo from '../../Components/skeletons/SkeletonCardVideo';
import CardClass from '../Courses/components/CardClass.vue';

export default {
  components: {
    SkeletonCardVideo,
    CardClass,
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'black',
    },
  },

  data() {
    return {
      isLoadingClassProgress: true,
      isLoadingClassCompleted: true,
      allInstructors: [],
    };
  },
  computed: {
    ...mapGetters(['getUserProgressClass', 'getUserCompletedClass']),
  },
  mounted() {
    this.fetchProgressClass();
    this.fetchCompletedClass();
  },
  methods: {
    fetchProgressClass(
      filter = {
        per_page: 3,
        course_video_current_time: 0,
        is_completed: 0,
      },
    ) {
      this.$store
        .dispatch(FETCH_USER_CLASS_PROGRESS, filter)
        .then(() => (this.isLoadingClassProgress = false))
        .catch(() => {
          this.isLoadingClassProgress = false;
          this.notify(
            'danger',
            'Tenemos problemas para cargar los cursos en progreso',
          );
        });
    },
    fetchCompletedClass(
      filter = {
        per_page: 3,
        is_completed: 1,
      },
    ) {
      this.$store
        .dispatch(FETCH_USER_CLASS_COMPLETED, filter)
        .then(() => (this.isLoadingClassCompleted = false))
        .catch(() => {
          this.isLoadingClassCompleted = false;
          this.notify(
            'danger',
            'Tenemos problemas para cargar los cursos completados',
          );
        });
    },
  },
};
</script>
<style scoped>
.user-progress-completed-classes {
  text-align-last: right !important;
}
</style>
