<template>
  <div class="page-container md-layout-column">
    <div class="container-fluid size my-progress">
      <div class="row">
        <div class="col-sm-6 mt-4">
          <h1>TODAS LAS CLASES COMPLETADAS</h1>
        </div>
        <div class="col-sm-6 mt-4 user-progress-completed-classes-all">
          <router-link class="text-decoration-none" to="/user/progreso">
            <button class="allClasses__container__classes__button rounded">
              Ver Progreso
            </button>
          </router-link>
        </div>

        <template v-if="isLoadingClassCompleted">
          <div class="col-sm-12">
            <div class="row">
              <div
                v-for="index in 3"
                :key="index"
                class="col-12 col-lg-4 col-md-6"
              >
                <skeleton-card-video />
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-if="getUserCompletedClass.items.length" class="col-sm-12">
            <div class="row">
              <card-class
                v-for="_class in getUserCompletedClass.items"
                :key="_class.id"
                :data="_class"
                :redirect="`/clase/${_class.slug}`"
                class="col-12 col-lg-4 col-md-6"
              />
            </div>
          </div>
          <div v-else class="col-sm-12 mt-4 mb-4">
            <h3>No hay contenido en progreso</h3>
          </div>
        </template>
      </div>
      <div
        v-if="totalPages > 1"
        class="card-footer pt-4 pb-2 d-flex justify-content-end border-top-0"
      >
        <base-pagination
          :value="page"
          class="pagination-style"
          :page-count="totalPages"
          @input="paginationChange"
        ></base-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FETCH_USER_CLASS_COMPLETED } from '../../../store/actions/user';
import SkeletonCardVideo from '../../Components/skeletons/SkeletonCardVideo';
import CardClass from '../Courses/components/CardClass.vue';
import BasePagination from '../../../components/BasePagination';

export default {
  name: 'UserProgressCompletedClasses',
  components: {
    SkeletonCardVideo,
    CardClass,
    BasePagination,
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'black',
    },
    perPage: {
      type: Number,
      default: 20,
    },
  },

  data() {
    return {
      isLoadingClassCompleted: true,
      page: 1,
    };
  },
  computed: {
    ...mapGetters(['getUserCompletedClass']),
    totalPages() {
      return Math.ceil(this.getUserCompletedClass.total / this.perPage);
    },
  },
  mounted() {
    this.fetchCompletedClass();
  },
  methods: {
    fetchCompletedClass(
      filter = {
        per_page: 20,
        is_completed: 1,
      },
    ) {
      this.$store
        .dispatch(FETCH_USER_CLASS_COMPLETED, filter)
        .then(() => (this.isLoadingClassCompleted = false))
        .catch(() => {
          this.isLoadingClassCompleted = false;
          this.notify(
            'danger',
            'Tenemos problemas para cargar los cursos completados',
          );
        });
    },
    async paginationChange(page) {
      this.isLoading = true;
      this.page = page;
      await this.$store.dispatch(FETCH_USER_CLASS_COMPLETED, {
        page: this.page,
        per_page: this.perPage,
      });
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.user-progress-completed-classes-all {
  text-align-last: right !important;
}
</style>
